const data = [
  {
    id: 1,
    text: "Impressum",
    url: "/impressum",
  },

  {
    id: 2,
    text: "Datenschutzerklärung",
    url: "/datenschutzerklaerung/",
  },
]

export default data
