import logo from "../assets/images/logo.png"
import { Link } from "gatsby"
import React from "react"
import socialLinks from "../constants/foot"
const Footer = () => {
  return (
    <footer className="footer font-serif text-base bg-MedienwerkstattGreen text-white">
      <div>
        <div className="footer-links social-links text-base">
          {socialLinks.map(link => {
            return (
              <Link key={link.id} to={link.url}>
                {link.text}
              </Link>
            )
          })}
        </div>
        <h6 className="text-MedienwerkstattDarkBlue">
          copyright&copy;{new Date().getFullYear()}
          <span>Medienwerkstatt Niederrhein Krefeld</span>
        </h6>
      </div>
    </footer>
  )
}

export default Footer
