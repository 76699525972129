const data = [
  {
    id: 1,
    text: "Startseite",
    url: "/",
  },

  {
    id: 2,
    text: "Kontakt",
    url: "/contact/",
  },
  {
    id: 3,
    text: "Arbeiten",
    url: "/projekte/",
  },
  {
    id: 4,
    text: "Werkzeuge",
    url: "/versprechen/",
  },
]

export default data
